import { useEffect } from "react";
import { Typography, TableCell, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";

import { StyledTableRow } from "CustomStyledComponents";
import { TableWrapper } from "components";
import { CustomTextField, ErrorMsg } from "../ShipmentCustomComponents";
import { DO_lineItemHeaders, lineItemHeaders } from "../constants";
import { getFixedValue, moneyFormat, getAttributes, validateNull } from "utils";

const ShipmentCreationTable = ({ lineItemsDetails, releasedQty }) => {
  const orderDetails = useSelector((state) => state.orderDetails);

  const {
    formState: { errors },
    getValues,
    setValue,
    control,
    watch,
  } = useFormContext();

  const replaceComma = (val) => {
    if (val) {
      const res = val?.replaceAll(",", "");
      return Number(res);
    } else {
      return 0;
    }
  };

  /**
   * @description SetValue totalDoReleasedQty of lineItemsDetails
   */
  useEffect(() => {
    lineItemsDetails.forEach((item) => {
      setValue(
        `lineItemsDetails.${item.lineItemId}.totalDoReleasedQty`,
        `${releasedQty?.[item?.lineItemId]?.totalQuantityReleased ?? 0}`,
      );
      setValue(
        `lineItemsDetails.${item.lineItemId}.totalQuantityShipped`,
        `${releasedQty?.[item?.lineItemId]?.totalQuantityShipped ?? 0}`,
      );
      setValue(
        `lineItemsDetails.${item.lineItemId}.totalDoQuantityShortClosed`,
        `${releasedQty?.[item?.lineItemId]?.totalDoQuantityShortClosed ?? 0}`,
      );
      const pendingReleaseQuantity =
        +(releasedQty?.[item?.lineItemId]?.totalQuantityReleased || 0) -
        +(releasedQty?.[item?.lineItemId]?.totalQuantityShipped || 0) -
        +(releasedQty?.[item?.lineItemId]?.totalDoQuantityShortClosed || 0);

      setValue(
        `lineItemsDetails.${item?.lineItemId}.pendingReleaseQuantity`,
        `${pendingReleaseQuantity.toFixed(2) ?? 0}`,
      );
    });
  }, []);

  const calculateFinalValue = (id) => {
    if (orderDetails) {
      const { lineItemDetails } = orderDetails;
      const qtyOrderedObj =
        lineItemDetails &&
        lineItemDetails?.filter((ele) => ele.lineItemId === id);
      if (!qtyOrderedObj || !qtyOrderedObj.length) return;
      let {
        orderedValueWithTax: { displayAmount: orderedValueWithTax },
        quantityOrdered,
        serviceCost: { displayAmount: serviceCost },
      } = qtyOrderedObj[0];

      const lineItemFormValues = getValues("lineItemsDetails");

      orderedValueWithTax = replaceComma(orderedValueWithTax);
      serviceCost = replaceComma(serviceCost);

      let res = 0;
      const qtyEntered = lineItemFormValues[id]["enteredQuantity"];

      if (orderedValueWithTax && quantityOrdered && serviceCost >= 0) {
        res =
          (Number(qtyEntered) / Number(quantityOrdered)) *
          (Number(orderedValueWithTax) + Number(serviceCost));
      }
      setValue(`lineItemsDetails.${id}.shipmentValue`, moneyFormat(res));
    }
  };

  const isDoEnabled = !orderDetails?.isDoFlowDisabled;
  return (
    <>
      {!!lineItemsDetails?.length && (
        <TableWrapper
          tableHeader={
            isDoEnabled ? (
              <>
                {DO_lineItemHeaders.map((item) => {
                  return (
                    <TableCell key={item}>
                      <Typography>{item}</Typography>
                    </TableCell>
                  );
                })}
              </>
            ) : (
              <>
                {lineItemHeaders.map((item) => {
                  return (
                    <TableCell key={item}>
                      <Typography>{item}</Typography>
                    </TableCell>
                  );
                })}
              </>
            )
          }
          /* commented for DO flow
          tableHeader={DO_lineItemHeaders.map((item) => {
            return (
              <TableCell key={item}>
                <Typography>{item}</Typography>
              </TableCell>
            );
          })}*/
          tableBody={lineItemsDetails.map((row) => {
            const pendingQty = +watch(
              `lineItemsDetails.${row.lineItemId}.pendingReleaseQuantity`,
            );

            return (
              <>
                <StyledTableRow key={row.lineItemId}>
                  <TableCell width={"25%"}>
                    {row?.productInfo?.name}
                    {getAttributes(row?.productInfo?.attributes)}
                  </TableCell>
                  <TableCell width={"25%"}>
                    {validateNull(row?.variantMMId)}
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`lineItemsDetails.${row.lineItemId}.quantity`}
                      defaultValue={row?.quantity ?? 0}
                      render={() => (
                        <>
                          {row?.productInfo?.primaryQuantity}
                          {!!row?.productInfo?.secondaryQuantity &&
                            ` | ${row?.productInfo?.secondaryQuantity}`}
                        </>
                      )}
                    />
                  </TableCell>
                  {isDoEnabled ? (
                    <TableCell>
                      <Controller
                        control={control}
                        name={`lineItemsDetails.${row.lineItemId}.pendingReleaseQuantity`}
                        defaultValue={pendingQty}
                        render={() => (
                          <Typography>
                            {`${pendingQty} ${
                              row?.productInfo?.primaryQuantity?.split(" ")[1]
                            }`}
                          </Typography>
                        )}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Controller
                        control={control}
                        name={`lineItemsDetails.${row.lineItemId}.alreadyPlannedQty`}
                        defaultValue={row?.alreadyPlannedQuantity}
                        render={({ field: { value } }) => (
                          <Typography>
                            {getFixedValue(value ?? 0, 4)}
                          </Typography>
                        )}
                      />
                    </TableCell>
                  )}
                  {/* commented for DO flow
                  <TableCell>
                    <Controller
                      control={control}
                      name={`lineItemsDetails.${row.lineItemId}.pendingReleaseQuantity`}
                      defaultValue={pendingQty}
                      render={() => (
                        <Typography>
                          {`${pendingQty} ${
                            row?.productInfo?.primaryQuantity?.split(" ")[1]
                          }`}
                        </Typography>
                      )}
                    />
                  </TableCell>*/}
                  <TableCell width={"20%"}>
                    <Controller
                      control={control}
                      name={`lineItemsDetails.${row.lineItemId}.enteredQuantity`}
                      defaultValue={"0"}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <>
                          <Box style={{ width: "80%" }}>
                            <CustomTextField
                              value={value}
                              onChange={(evt) => {
                                const enteredValue = +getFixedValue(
                                  evt?.target?.value,
                                  4,
                                );

                                onChange(enteredValue);
                                calculateFinalValue(row?.lineItemId);
                              }}
                              customPadding={2}
                              onBlur={onBlur}
                              type={"number"}
                              disabled={isDoEnabled && pendingQty <= 0}
                              decimal
                              highlightError={errors?.lineItemsDetails?.[
                                row.lineItemId
                              ]?.enteredQuantity?.hasOwnProperty("message")}
                            />
                          </Box>
                          <ErrorMsg
                            msg={errors?.lineItemsDetails?.[
                              row.lineItemId
                            ]?.enteredQuantity?.message?.substring(0, 30)}
                          />
                        </>
                      )}
                    />
                  </TableCell>
                  <TableCell width={"15%"}>
                    <Controller
                      control={control}
                      name={`lineItemsDetails.${row.lineItemId}.shipmentValue`}
                      defaultValue={0}
                      render={({ field: { value } }) => (
                        <Typography>{value}</Typography>
                      )}
                    />
                  </TableCell>
                </StyledTableRow>
              </>
            );
          })}
        />
      )}
    </>
  );
};

export default ShipmentCreationTable;
